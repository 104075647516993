import React from 'react'

export default function Exp({ vitaExp, manaExp, desiredVitaExp, desiredManaExp }) {
  return (
    <div className="exp" style={{ height: 40 }}>
      {desiredVitaExp > vitaExp && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ marginRight: 5, color: 'lightgreen', fontWeight: 'bold' }}>
            {`${(((desiredVitaExp - vitaExp) / 1000).toFixed(5) / 1).toLocaleString()}b`}
          </div>
          <div style={{ color: 'aliceblue' }}>{'needed for desired vita.'}</div>
        </div>
      )}
      {desiredManaExp > manaExp && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ marginRight: 5, color: 'lightgreen', fontWeight: 'bold' }}>
            {`${(((desiredManaExp - manaExp) / 1000).toFixed(5) / 1).toLocaleString()}b`}
          </div>
          <div style={{ color: 'aliceblue' }}>{'needed for desired mana.'}</div>
        </div>
      )}
    </div>
  )
}
